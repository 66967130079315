.searchbox input {
    box-shadow: 0px 0px 3px 0px #E1E2FF;
    border-radius: 10px;
    width: 100% !important;
    padding:10px;
    padding: 0;
   /* background: url('../../public/images/Search-icon.svg') no-repeat 18px center transparent !important;*/
}
.searchbox svg{
	display: none;
}
.plus-button{
	background: #00023D;
	width: 36px;
	height: 36px;
	border:none;
	border-radius: 100%;
	cursor: pointer;	
}
.chat-details ul{
	list-style-type:none;
	padding:0;
	width:100%;
}
.chatboxdts{	
    align-items: center;
    width:calc(100% - 24px);
    padding:10px 12px;
    margin: 6px;
    box-shadow: 0px 0px 8px 0px transparent;
    border: 1px solid transparent;
}
.chatlist-names {
    width: 65%;
    padding-left: 15px;
}
.activechat{
    box-shadow: 0px 0px 8px 0px #B7B9FF;
    border: 1px solid #DFE0EB;
    border-radius: 10px;    
}
.chatboxdts:hover{
	background:#F5F5F5;
	border: 1px solid #F5F5F5;
	box-shadow: none;
	border-radius: 0; 
}
.activechat.chatboxdts:hover{
	box-shadow: none;
    border: 1px solid transparent;
    border-radius: 0;
    background: transparent !important;
}
.chatlist-names h3 span{
	color: #000000;
	font-family: 'PublicSans-Bold';
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 5px;
}
.chatlist-names h5{
	 font-family: 'PublicSans-Regular';
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;

}
.chatlist-times{
	font-family: 'Inter-Regular';
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: right;
	color:#707991;
}
.count-noti{
	background: #78E378;
	width: 18px;
	height: 18px;
	border-radius: 100px;
	font-family: 'Inter-Regular';
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: center;
	color:#FFFFFF;
	display: inline-block;
}
.chatboxdts.separatechatbot{
	border:none;
	width:calc(100% - 50px);
	padding:0;
	justify-content: space-between;
	padding:20px;
	border: 1px solid #DFE0EB;
	border-radius: 10px 10px 0 0;
	margin:0 !important;
}
.chatboxdts.separatechatbot:hover{
	box-shadow: none;
}
.info-circle{
	/*background: url('../../public/images/info-icon.svg') no-repeat 0 0 transparent;*/
	width: 17.74px;
	height: 17.74px;
	display: inline-block;
	cursor:pointer;
}

.border-1{
	border: 1px solid #DFE0EB;
}

/* */

.chatlist-image
{
	margin: 0 16px 0 0;
}
.project-tit span
{
	font-size: 16px;
	color: #000000;
	font-family: 'PublicSans-Bold';
}
.userchatbox .css-941vuf-MuiButtonBase-root-MuiListItemButton-root.Mui-selected
{
	background: none;
	border: none;
	padding:0;
}
.blue-chat-border li {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 15px !important;
    width: calc(100% - 40px) !important;
}
.blue-chat-border li.rightchat-section{
	display: flex;
	justify-content: end;
}
.blue-chat-border li.rightchat-section .rightchatdivs .chat-msg{
	background: #00023B !important;
}
.blue-chat-border li.rightchat-section .rightchatdivs .chat-msg span{
	color:#ffffff;
}
.blue-chat-border li.rightchat-section .rightchatdivs .date_time p{
	text-align: right;
}
li.rightchat-section .rightchatdivs .chatlist-image {
    margin: 0 0 0 16px;
}
.blue-chat-border li .chat-msg
{
	border: 1px solid #2B3674 !important;
	background: none !important;
	margin-bottom: 0px !important;
	padding:10px;
	border-radius:10px;
	min-width: 320px;
    max-width: 320px;
    max-height: 35px;
    min-height: 35px;
}
.chat-msg
{
	font-size: 12px;
	color: #00023B;
}

.communi-box .MuiPaper-elevation.MuiPaper-rounded
{
	box-shadow: none;
}

.userchatbox li {
    padding: 0;
}
.todays-chat {
    box-shadow: 2px 3px 6px 0px #00000026 inset;
    position: relative;
    height: 5px;
    width: calc(100% - 40px);
    margin: 20px;
    text-align: center;
    color:#00023B;
}
.todays-chat h3 {
    color: #787878;
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.075em;
    text-align: center;
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: auto;
}
.communi-box .chat-msg span {
	 font-family: 'Roboto-Regular';
	font-size: 12px;
	font-weight: 400;
	line-height: 14.06px;
	letter-spacing: 0.075em;
	text-align: left;
}
.date_time p{
	font-family: 'PublicSans-Regular';
	font-size: 10px;
	font-weight: 400;
	line-height: 11.75px;
	text-align: left;
	color: #333333;
}
.mb-20{
	margin-bottom: 20px;
}
.infinite-scroll-component {
	margin-bottom: 20px;
}
.chating-box{
	position: relative;
}
.icons-set-btns {
    position: absolute;
    right: 24px;
    top: 28px;
}
.icon-button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0 5px;
    height: auto;
    line-height: normal;
}
.message-input,
.message-input:focus {
    border: 1px solid #A5A6F6;
    border-radius: 5px;
    width: 75%;
    margin: 14px;
    min-height: 30px;
    padding: 10px 120px 10px 10px;
    resize: none;
}