
.project-count {
    background: linear-gradient(to right,  #10003b 0%,#2989d8 100%,#4318ff 100%);
    border-radius: 20px;
    max-width: 100%;
    padding: 20px 20px 16px;
    margin: 0 24px 25px 0;
    width: calc(100% - 60px);
	min-height: 50px;
}
.project-count p{
color: #fff; 
font-size: 14px;   
font-family: 'DMSans-Regular';
margin:0px 0 3px 0;
}
.project-count h4
{
	font-family: 'DMSans-Bold';
	color: #fff; font-size: 24px; 
}
.chart-bar
{
	float: right;
}
.chart-bar img
{
	padding: 0 4px;
}
.dashbooard-title h3
{
	color: #1B2559;
	font-size: 22px;
	font-family: 'DMSans-Bold';
}
.dashbooard-title h5
{
	color: #1B2559;
	font-size: 18px;
	font-family: 'DMSans-Bold';
}
.boxborder-1
{
	border: 1px solid #C5C5D3
}
.project-email-text
{
	font-size: 14px;
	color: #fff;
	font-family: 'DMSans-Bold';
	text-align: center;
	font-weight: 700;
}
.project-hrs
{	
	font-size: 18px;
	font-family: 'DMSans-Bold';
	color: #fff;
	text-align: center;
	width: 70%;
	font-weight: 700;
}
.email-collab-box
{
	margin: 42px 0 0;
}
.email-collab-box .css-mhc70k-MuiGrid-root>.MuiGrid-item{
    padding-left: 6px;
}
.tasks-div h4
{
	font-family: 'DMSans-Regular';
	font-size: 18px;
}
.task-img img
{
	 margin-right: 10px;
}
.tasks-div p{margin: 0;}
.tot-task
{
	justify-content: space-between;
    align-items: center;
}

/* Tenant Dashboard */
.assigned-tasks .chart-bar
{
	float: none;
	margin: 24px 0 10px;
}
.assigned-tasks .project-count   p
{
	font-size: 18px;
	font-family: 'DMSans-Bold';
}
.assigned-tasks .project-count h4 {
    font-family: 'DMSans-Bold';
    color: #fff;
    font-size: 48px;
	margin: 30px 0 0px;
}
.assigned-tasks .chart-bar img {
    padding: 0 6px;
}
.assigned-tasks .project-count {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00023b+0,4318ff+100 */
	background: linear-gradient(to bottom,  #00023b 0%,#4318ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-radius: 20px;
    max-width: 100%;
    padding: 30px 20px 16px;
    margin: 0 24px 25px 0;
    width: calc(100% - 60px);
}
/* Tenant Dashboard */