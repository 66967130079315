.blue-bg{
	background: url("../../../public/images/overlay-shape-img.svg") no-repeat 0 0 #00023B;
	background-size: cover;
	height:100%;
	width: calc(100% - 60px);
    padding: 30px 30px 12px 30px 
}

.form-signin{
	text-align: left;
}
.form-check-label{
	font-family: 'OpenSans-Regular';
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	color:#737D8C;
}
.forgotpasswd{
	font-family: 'OpenSans-Regular';
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	text-align: right;
	color:#737D8C;
	text-decoration: underline;
}
.forgotpasswd a{
	font-family: 'OpenSans-Regular';
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	text-align: right;
	color:#737D8C;
	text-decoration: underline;
	cursor: pointer;
}
.login-links-section{
	margin:30px 0 0 0;
}
.login-links-section .blue-button{
	margin:0 0 12px 0;
}
.signuplinks a{
	 font-family: 'OpenSans-Semibold';
	font-size: 14px;
	font-weight: 600;
	line-height: 19px;
	color: #00023b;
	text-decoration: none;
}
.signuplinks{
	font-family: 'OpenSans-Regular';
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	color: #737D8C;
	text-decoration: underline;
}
.login-section{
	height: 100vh;
	min-height: 100%;
}
.login-links-section .primary-button{
	width:100%;
	margin-bottom: 20px;
}