/*---------Sidebar starts here----------*/
.sidebars-fixed{
	position: fixed;
	width:290px;
	height: 100%;
	background-color:#ffff;
}
.b-bottom{
	border-bottom: 1px solid #F4F7FE4D;
}
.font1{
	font-family: 'Poppins', sans-serif;
}
.fw-700{	
	font-weight: 700;
}
.fs-logo{
	font-size: 26px;
	color:#fff;
	text-decoration: none;
}
.logo-img{
	margin:5px 38px;
}
.sidebar-menu{
	padding:0px 38px;
	list-style: none;
	width: calc(100% - 82px);
}
.sidebar-menu li{
	width: 100%;
	margin-bottom: 15px;
}
.sidebar-menu li a{
	font-size: 16px ;
	font-weight:500;
	font-family: 'DMSans-Medium';
	color: #A3AED0;
	padding: 12px;
	display: flex;
	text-decoration: none;
}
.sidebar-menu li:hover a,
.sidebar-menu li.active a{
	color:#fff;
	background: #00023B;
	border-radius: 5px;
}
.sidebar-menu li a .menu-icons{
	display: inline-block;
	width: 20px;
    height: 20px;
    margin: 0 14px;
    line-height: normal;
}

.sidebar-menu li a .dashboard-icon{
	background:url("../../public/images/dashboard.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .dashboard-icon,
.sidebar-menu li:hover a .dashboard-icon{
	background:url("../../public/images/dashboard-active.svg") no-repeat center center transparent;
}

.sidebar-menu li a .projects-icon{
	background:url("../../public/images/projects.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .projects-icon,
.sidebar-menu li:hover a .projects-icon{
	background:url("../../public/images/projects-active.svg") no-repeat center center transparent;
}

.sidebar-menu li a .communications-icon{
	background:url("../../public/images/communications.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .communications-icon,
.sidebar-menu li:hover a .communications-icon{
	background:url("../../public/images/communications-active.svg") no-repeat center center transparent;
}

.sidebar-menu li a .settings-icon{
	background:url("../../public/images/settings.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .settings-icon,
.sidebar-menu li:hover a .settings-icon{
	background:url("../../public/images/settings-active.svg") no-repeat center center transparent;
}

.sidebar-menu li a .team-icon{
	background:url("../../public/images/team.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .team-icon,
.sidebar-menu li:hover a .team-icon{
	background:url("../../public/images/team-active.svg") no-repeat center center transparent;
}


.sidebar-menu li a .tasks-icon{
	background:url("../../public/images/tasks.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .tasks-icon,
.sidebar-menu li:hover a .tasks-icon{
	background:url("../../public/images/tasks-active.svg") no-repeat center center transparent;
}

.sidebar-menu li a .schedules-icon{
	background:url("../../public/images/schedules.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .schedules-icon,
.sidebar-menu li:hover a .schedules-icon{
	background:url("../../public/images/schedules-active.svg") no-repeat center center transparent;
}

.sidebar-menu li a .logout-icon{
	background:url("../../public/images/logout.svg") no-repeat center center transparent;
}
.sidebar-menu li.active a .logout-icon,
.sidebar-menu li:hover a .logout-icon{
	background:url("../../public/images/logout-active.svg") no-repeat center center transparent;
}

.coming-soon-sec{
	border-radius: 24px;
	background: linear-gradient(135deg, #868CFF 0%, #00023B 100%);
	padding:40px 0 54px 0;
	width: 228px;
	margin:100px auto 40px auto;
}
.coming-soon-sec h5{
	color: #FFF;
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px; 
	letter-spacing: -0.4px;
	text-transform: uppercase;
	padding-bottom: 20px;
}
.coming-soon-sec h6{
	color: #6AD2FF;
	text-align: center;
	font-family: 'DM Sans', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -0.28px;
	text-transform: uppercase;
	word-wrap: break-word;
    padding: 0 20px;
}
/*---------Sidebar ends here----------*/
/*---------Header starts here--------*/
.header-names{
	font-family: 'DMSans-Bold';
	font-size: 14px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: -0.02em;
	text-align: left;
	color:#707EAE;
}
h3.header-welcome{	
	font-family: 'DMSans-Medium';
	font-size: 34px;
	font-weight: 600;
	line-height: 41px;
	letter-spacing: 0em;
	text-align: left;
	color: #2A323C;
}
h3.header-welcome span{	
	font-weight: 300;
	font-family: 'DMSans-Regular';
}
.right-header .dropdown-toggle:after{
	content:"";
}

/*---------Header ends here--------*/