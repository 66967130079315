.select-dropdown .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
{
	width: 100%;
}

.primarynext-button {
    height: 38px;
    border-radius: 24px;
    background: #00023B;
    box-shadow: 0px 3px 6px 0px #0E3E8440;
    border: none;
    color: #FFFFFF;
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    padding: 0 22px;
	margin: 0 0px 0 20px;
}
.login-links-section.text-right
{
	float: right;
}