.whitebg-shadow{
	box-shadow: 0px 0px 30px 0px #00023B;
	border-radius: 30px;
}
.blue-slant-button button,
.blue-slant-button button:hover{
	box-shadow: 3px -9px 7px 0px #00000008;
    background:url("../../../public/images/right-curve.png") no-repeat center right transparent;
    border-radius:0 !important;
    border: none;
    color: #FFFCFC;
    font-family: 'Lato-Bold';
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    padding: 0 26px 0 0;
    border-radius:8px 0 0 0;
    margin-bottom: 15px;
}
.blue-slant-button button .button-bg{
	background-color: #00023B;
	height:36px;
	display: flex;
    align-items: center;
    justify-content: center;
    padding-left:17px;
    border-radius:8px 0 0 0;
}
.btn-icons-align{
	margin-right: 4px;
    line-height: normal;
    height: 22px;
}
.prj-title-tags-box{
	background: #F4F7FE;
	border-radius: 8px;
	border: 1px solid #00023B;
	padding:12px;
	margin:10px 20px 10px 0;
}
.prj-title{
	font-family: 'Inter-Bold';
	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
	text-align: left;
	color: #16161D;
}
.prj-tags{
	font-family: 'Inter-Regular';
	font-size: 12px;
	font-weight: 400;
	line-height: 14.4px;
	text-align: left;
	color: #16161D;
}
.resident-links a{
	font-family: 'Inter-Regular';
	font-size: 12px;
	font-weight: 400;
	line-height: 14.4px;
	text-align: left;
	color: #00023B;
	text-decoration: none;
	background: #FFF5CC;
	padding:8px 7px;
	border-radius: 5px;
	display: flex;
    align-items: center;
}
.beige-bg-lite{
	background:#F4F7FE;
	padding:16px 10px;
	border-radius: 8px;
	margin:20px 0;
	width: calc(100% - 20px);
}
.pending-bg{
	background: #FFF5CC;
	padding:10px 16px;
	border-radius: 30px;
	color:#CCA300;
	font-family: 'Inter-Medium';
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
}
.label-value-styles {
    display: flex;
    margin: 0 0 20px 0;
    width: 100%;
}
.label-value-styles label{
	font-family: 'DMSans-Medium';
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.02em;
	text-align: left;
	color:#A3AED0;
	margin-right:13px;
	width:160px;
}
.label-value-styles span{
	font-family: 'Inter-Bold';
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
	text-align: left;
	color:#16161D;
	width: 130px;
    text-align: left;
}
.right-align-desk .label-value-styles{
	justify-content: end;
}

.avatars {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
}
.avatar a {
    position: relative;
    width: 28px;
    height: 28px;
}
.avatar img {
    width: 28px;
    height: 28px;
    border: 0.62px solid #E5E9F2;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}
.avatars .avatar:nth-child(1) img{
	left:0;
}
.avatars .avatar:nth-child(2) img{
	left:-7px;
}
.avatars .avatar:nth-child(3) img{
	left:-14px;
}
.others-users a{
	font-family: 'Poppins-Regular';
	font-size: 9.23px;
	font-weight: 400;
	line-height: normal;
	text-align: left;
	color:#4F5459;
}
.project-users-icons-set{
	display:flex;
	align-items: center;
	flex-direction: row;
}

.project-users-icons-set .prj-title {   
    width: 330px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}